import Button from 'components/Button'
import TextField from 'components/TextField'
import { signIn } from 'next-auth/client'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { FieldErrors, signInValidate } from 'utils/validations'

const FormSignIn = () => {
  const [formError, setFormError] = useState('')
  const [fieldError, setFieldError] = useState<FieldErrors>({})
  const [values, setValues] = useState({ email: '', password: '' })
  const [loading, setLoading] = useState(false)
  const routes = useRouter()
  const { push, query } = routes

  const handleInput = (field: string, value: string) => {
    setValues((s) => ({ ...s, [field]: value }))
  }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)

    const errors = signInValidate(values)

    if (Object.keys(errors).length) {
      setFieldError(errors)
      setLoading(false)
      return
    }

    setFieldError({})
    // sign in
    const domain = process.env.NEXT_APP_URL || window.location.origin
    const result = await signIn('credentials', {
      ...values,
      redirect: false,
      callbackUrl: `${domain}${query?.callbackUrl || '/dashboard'}`
    })

    if (result?.url) {
      return push(result?.url)
    }

    // jogar o erro
    setFormError('Email ou senha inválidos')
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <TextField
        label="E-mail"
        name="email"
        placeholder="Entre com seu email"
        type="email"
        error={fieldError?.email}
        onInputChange={(v) => handleInput('email', v)}
      />

      <TextField
        label="Senha"
        name="password"
        placeholder="Entre com sua senha"
        type="password"
        error={fieldError?.password}
        onInputChange={(v) => handleInput('password', v)}
      />

      <div className="space-y-1 mt-2">
        <label className="label">
          <span className="label-text text-error">{formError}</span>
        </label>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember_me"
            name="remember_me"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-base-300 rounded"
          />
          <label
            htmlFor="remember_me"
            className="ml-2 block text-sm text-gray-500"
          >
            Lembrar
          </label>
        </div>

        <div className="text-sm">
          <a
            href="/forgot-password"
            className="font-medium text-primary hover:text-indigo-500"
          >
            Esqueceu sua senha?
          </a>
        </div>
      </div>

      <div>
        <Button label="Entrar" type="submit" loading={loading} />
      </div>
    </form>
  )
}

export default FormSignIn
