import { InputHTMLAttributes, useState } from 'react'

export type TextFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  initialValue?: string
  icon?: React.ReactNode
  error?: string
  attachedLabel?: string
  classLabel?: string
  classFormControl?: string
  updatedField?: string
  disabled?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const TextField = ({
  label,
  name,
  initialValue = '',
  disabled = false,
  onInputChange,
  classLabel,
  error,
  attachedLabel,
  classFormControl,
  updatedField,
  ...props
}: TextFieldProps) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInputChange && onInputChange(newValue)
  }

  return (
    <div className={classFormControl ? classFormControl : 'space-y-1'}>
      <label className="mt-1">
        <span
          className={
            classLabel ? classLabel : 'block text-sm font-medium text-gray-500'
          }
        >
          {label}
        </span>
      </label>
      <div className="mt-1">
        <input
          type="text"
          onChange={onChange}
          value={value}
          disabled={disabled}
          name={name}
          {...(label ? { id: name } : {})}
          {...props}
          className={`appearance-none ${
            disabled ? 'disabled:opacity-50' : ''
          } block w-full px-3 py-3 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
            updatedField ? 'border-yellow-600' : 'border-base-300'
          }`}
        />
        {!!attachedLabel && (
          <div className="space-y-1 mt-1">
            <label className="block text-sm">
              <span className="block text-sm sm:text-sm text-gray-400">
                {attachedLabel}
              </span>
            </label>
          </div>
        )}
        {!!error && (
          <div className="space-y-1 mt-1">
            <label className="block text-sm">
              <span className="block text-sm sm:text-sm text-error">
                {error}
              </span>
            </label>
          </div>
        )}
        {!!updatedField && (
          <div className="form-control">
            <label className="label">
              <span className="label-text text-yellow-600">
                Este campo foi atualizado <b>{updatedField}</b>
              </span>
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default TextField
