import FormSignIn from 'components/FormSignIn'
import Logo from 'components/Logo'
import Link from 'next/link'

export default function SignIn() {
  return (
    <div
      className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      style={{
        backgroundImage: `url('images/Image_bg.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'luminosity'
      }}
    >
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo />

            <h2 className="mt-6 text-3xl font-extrabold text-gray-500">
              Entrar na conta
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Ou{' '}
              <Link href="/register">
                <a
                  href="#"
                  className="font-medium hover:text-indigo-500 text-primary"
                >
                  crie sua conta
                </a>
              </Link>
            </p>
          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mt-8">
              <div className="mt-6">
                <FormSignIn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
